import React from 'react';
import Social from 'components/Social/Social';
import { useLoopItems } from 'shared/hooks/loopItems';
import { shuffleArray } from 'shared/utils/array';
import style from './Home.module.css';

const varTitles = shuffleArray([
  'CTO',
  'Co-founder',
  'Full-Stack',
  'Front-end',
  'Back-end',
  'Software Architect',
  'Software Designer',
  'Teaching Assistant',
]);

export interface HomeProps {}

const Home = ({}: HomeProps) => {
  const curTitle = useLoopItems(varTitles, 1);

  return (
    <div className={style.container}>
      <span className={style.salute}>Hello!</span>
      <span className={style.name}>
        <span>I'm </span>
        <span>David Narvaez</span>
      </span>
      <span className={style.title}>Software and Computer Engineer & </span>
      <span className={style.variableTitle}>{curTitle}</span>
      <div className={style.social}>
        <Social />
      </div>
    </div>
  );
};

export default Home;
