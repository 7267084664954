import React from 'react';
import style from './SkillsDetail.module.css';

export interface SkillModel {
  name: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  expertise: 'expert' | 'advanced' | 'experienced' | 'beginner';
}

export interface SkillsDetailProps {
  skills: SkillModel[];
}

const SkillsDetail = ({ skills }: SkillsDetailProps) => {
  return (
    <div className={style.container}>
      {skills.map((sk) => (
        <div key={sk.name} className={style.skill}>
          <div className={style.skillData}>
            <sk.icon className={style.icon} />
            <b>{sk.name}</b>
            <span>{sk.expertise}</span>
          </div>
          <div className={style.backgroundContainer}>
            <sk.icon className={style.iconBackground} />
            <div className={style.backgroundFilter} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default SkillsDetail;
