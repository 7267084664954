export const publicFile = (path: string) => {
  return process.env.PUBLIC_URL + path;
};

export const downloadFile = (filePath: string) => {
  var link = document.createElement('a');
  link.rel = 'noreferrer';
  link.href = filePath;
  link.download = filePath.substring(filePath.lastIndexOf('/') + 1);
  link.click();
};
