import { SkillModel } from 'components/Skills/SkillsDetail/SkillsDetail';
import { ReactComponent as MongodbIcon } from './mongodb.svg';
import { ReactComponent as Neo4jIcon } from './neo4j.svg';
import { ReactComponent as NodejsIcon } from './nodejs.svg';
import { ReactComponent as PostgresqlIcon } from './postgresql.svg';
import { ReactComponent as PythonIcon } from './python.svg';
import { ReactComponent as RedisIcon } from './redis.svg';

export const backendSkills: SkillModel[] = [
  { name: 'Python', expertise: 'expert', icon: PythonIcon },
  { name: 'NodeJS', expertise: 'advanced', icon: NodejsIcon },
  { name: 'MongoDB', expertise: 'advanced', icon: MongodbIcon },
  { name: 'Neo4j', expertise: 'advanced', icon: Neo4jIcon },
  { name: 'PostgreSQL', expertise: 'advanced', icon: PostgresqlIcon },
  { name: 'Redis', expertise: 'advanced', icon: RedisIcon },
];
