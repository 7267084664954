import React, { useEffect, useMemo, useRef, useState } from 'react';
import style from './Blob.module.css';

const duration = 1.2;
const values = [
  'M304.413 3.66216C266.913 10.1963 176.412 15.6623 141.413 12.6622C106.413 9.66209 19.9123 -7.33773 7.91247 3.66223C-4.08735 14.6622 -3.5872 82.01 17.4127 71.6622C38.4126 61.3144 347.413 86.662 323.413 59.1621C299.413 31.6623 341.913 -2.87194 304.413 3.66216Z',
  'M1255.1 39.8314C1034.53 -8.28614 51.41 -14.3744 19.848 32.1819C-11.7141 78.7381 -0.603469 178.408 18.5641 239.507C37.7317 300.607 168.747 291.254 577.556 242.969C986.366 194.684 1221.41 278.854 1335 242.969C1448.59 207.084 1475.67 87.949 1255.1 39.8314Z',
  'M1151.5 806.5C1084.5 665 215.957 -12.0457 161.199 0.806967C106.441 13.6597 47.076 94.4892 19.772 152.411C-7.53204 210.334 -66.5 589.5 360.5 698C787.5 806.5 877 976.5 985.578 1046.97C1094.16 1117.44 1218.5 948 1151.5 806.5Z',
  'M740.5 1014C774 470 525.5 673.843 525.5 470C525.5 266.157 605.981 156.494 525.5 64.9999C445.019 -26.4944 222.5 -16 111 65C-0.500006 146 -86 1177.5 153.5 1272C393 1366.5 707 1558 740.5 1014Z',
];

export interface BlobProps {
  newPathIndex: number;
  className?: string;
}

const Blob = ({ newPathIndex, className }: BlobProps) => {
  const [curPath, setCurPath] = useState<number>(0);
  const animation = useRef<SVGAnimateElement>(null);

  useEffect(() => {
    animation.current?.beginElement();

    const timer = setTimeout(() => {
      setCurPath(newPathIndex);
    }, duration * 1000);

    return () => clearTimeout(timer);
  }, [newPathIndex]);

  const curValues = useMemo(() => {
    return [values[curPath], values[newPathIndex]];
  }, [curPath, newPathIndex]);

  return (
    <svg
      viewBox={'0 0 2000 2000'}
      fill="red"
      xmlns="http://www.w3.org/2000/svg"
      className={`${style.blob} ${className}`}
      data-type={newPathIndex}>
      <defs>
        <linearGradient
          id="paint0_linear_84_349"
          x1="0.412463"
          y1="36.6623"
          x2="316.412"
          y2="36.6623"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F1C984" />
          <stop offset="1" stopColor="#E29E7D" />
        </linearGradient>
      </defs>
      <path d={curValues[0]} fill="url(#paint0_linear_84_349)">
        <animate
          ref={animation}
          fill="freeze"
          attributeName="d"
          dur={`${duration}s`}
          repeatCount="1"
          values={curValues.join(';')}
          calcMode="spline"
          keySplines=" 0.185,0.675,0,0.995"
        />
      </path>
    </svg>
  );
};

export default Blob;
