import React, { useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Blob from 'shared/components/Blob/Blob';
import { useResponsiveness } from 'shared/hooks/responsiveness';
import { downloadFile, publicFile } from 'shared/utils/files';
import style from './App.module.css';
import { ReactComponent as DownloadIcon } from './assets/icons/download.svg';

enum Step {
  HOME = '/',
  BIO = '/bio',
  WORK = '/work',
  SKILLS = '/skills',
}

const STEP_INDEX: Record<Step, number> = {
  [Step.HOME]: -1,
  [Step.BIO]: 0,
  [Step.WORK]: 1,
  [Step.SKILLS]: 2,
};

const titles: Record<Exclude<Step, Step.HOME>, string> = {
  [Step.BIO]: 'Bio',
  [Step.WORK]: 'Work',
  [Step.SKILLS]: 'Skills',
};

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useResponsiveness();

  const firstPath = useMemo(() => {
    return '/' + pathname.split('/')[1];
  }, [pathname]);

  const isHomePath = useMemo(() => {
    return firstPath === '/';
  }, [firstPath]);

  return (
    <>
      <div
        className={style.menu}
        data-contrast={[Step.BIO, ...(isMobile ? [Step.WORK] : [])].includes(firstPath as Step)}
        data-blur={isMobile && [Step.BIO].includes(firstPath as Step)}>
        <button className={style.menuItem} data-active={firstPath === Step.HOME} onClick={() => navigate(Step.HOME)}>
          Home
        </button>
        <button className={style.menuItem} data-active={firstPath === Step.BIO} onClick={() => navigate(Step.BIO)}>
          Bio
        </button>
        <button className={style.menuItem} data-active={firstPath === Step.WORK} onClick={() => navigate(Step.WORK)}>
          Work
        </button>
        <button
          className={style.menuItem}
          data-active={firstPath === Step.SKILLS}
          onClick={() => navigate(Step.SKILLS)}>
          Skills
        </button>
      </div>
      <Blob newPathIndex={STEP_INDEX[firstPath as Step] + 1} className={style.blob} />
      <div className={style.content}>
        {!isHomePath && <span className={style.title}>{(titles as any)[firstPath]}</span>}
        <Outlet />
      </div>
      <div className={style.floatingBtn} onClick={() => downloadFile(publicFile('/files/David Narvaez - CV.pdf'))}>
        <DownloadIcon />
        Download CV
      </div>
    </>
  );
}

export default App;
