import { SkillModel } from 'components/Skills/SkillsDetail/SkillsDetail';
import { ReactComponent as AngularIcon } from './angular.svg';
import { ReactComponent as CapacitorIcon } from './capacitor.svg';
import { ReactComponent as CSS3Icon } from './css3.svg';
import { ReactComponent as Html5Icon } from './html5.svg';
import { ReactComponent as JavascriptIcon } from './js.svg';
import { ReactComponent as KotlinIcon } from './kotlin.svg';
import { ReactComponent as PWAIcon } from './pwa.svg';
import { ReactComponent as ReactIcon } from './react.svg';
import { ReactComponent as TypescriptIcon } from './typescript.svg';

export const frontEndSkills: SkillModel[] = [
  { name: 'React', expertise: 'expert', icon: ReactIcon },
  { name: 'Typescript', expertise: 'expert', icon: TypescriptIcon },
  { name: 'HTML', expertise: 'expert', icon: Html5Icon },
  { name: 'CSS', expertise: 'expert', icon: CSS3Icon },
  { name: 'Javascript', expertise: 'expert', icon: JavascriptIcon },
  { name: 'React Native', expertise: 'expert', icon: ReactIcon },
  { name: 'Ionic/Capacitor', expertise: 'advanced', icon: CapacitorIcon },
  { name: 'Angular', expertise: 'advanced', icon: AngularIcon },
  { name: 'PWA', expertise: 'experienced', icon: PWAIcon },
  { name: 'Kotlin', expertise: 'beginner', icon: KotlinIcon },
];
