import React from 'react';
import { ReactComponent as GitHubIcon } from './icons/github.svg';
import { ReactComponent as LinkedInIcon } from './icons/linkedin.svg';
import { ReactComponent as MailInIcon } from './icons/mail.svg';
import style from './Social.module.css';

export interface SocialProps {}

const Social = ({}: SocialProps) => {
  return (
    <div className={style.container}>
      <a href="https://github.com/dnarvaez27" target="_blank" rel="noreferrer">
        <GitHubIcon className={style.icon} />
      </a>
      <a href="https://www.linkedin.com/in/dnarvaez27/" target="_blank" rel="noreferrer">
        <LinkedInIcon className={style.icon} />
      </a>
      <a href="mailto:dnarvaez27@outlook.com" target="_blank" rel="noreferrer">
        <MailInIcon className={style.icon} />
      </a>
    </div>
  );
};

export default Social;
