import React from 'react';
import style from './Extra.module.css';

export interface ExtraProps {
  
}

const Extra = ({}: ExtraProps) => {
  return (
    <div className={style.container}>
      <span>Under construction</span>
    </div>
  );
};

export default Extra;
