import React from 'react';
import style from './Achievements.module.css';
import { ReactComponent as BriefcaseIcon } from './icons/briefcase.svg';
import { ReactComponent as CalendarIcon } from './icons/calendar.svg';
import { ReactComponent as GraduationCapIcon } from './icons/graduation-cap.svg';
import { ReactComponent as TrophyIcon } from './icons/trophy.svg';
import Users100Src from './images/100K.png';
import HultImgSrc from './images/hult.png';
import SPPImgSrc from './images/spp.jpeg';
import TlaxcalaImgSrc from './images/tlaxcala.jpeg';
import UniandesImgSrc from './images/uniandes.jpg';
import YCImgSrc from './images/yc.png';

const achievements: {
  id: string;
  name: string;
  date: string;
  iconType: 'trophy' | 'event' | 'academic' | 'work';
  image: string;
}[] = [
  {
    id: 'f100K',
    name: 'First 100K users',
    date: 'Mar 2022',
    iconType: 'work',
    image: Users100Src,
  },
  {
    id: 'yc2020',
    name: 'YCombinator - W21',
    date: 'Aug 2021',
    iconType: 'trophy',
    image: YCImgSrc,
  },
  {
    id: 'hult2019',
    name: 'Uniandes Hult Prize - Finalist',
    date: 'Nov 2018',
    iconType: 'trophy',
    image: HultImgSrc,
  },
  {
    id: 'expoandes',
    name: '1st Place in \'Feria de Emprendimiento "Expoandes"\'',
    date: 'Nov 2015',
    iconType: 'trophy',
    image: UniandesImgSrc,
  },
  {
    id: 'spp2015',
    name: '"Ser Pilo Paga" Scholarship',
    date: 'Aug 2014',
    iconType: 'academic',
    image: SPPImgSrc,
  },
  {
    id: 'gioia2014',
    name: 'Festival Tlaxcala Canta',
    date: 'Nov 2014',
    iconType: 'event',
    image: TlaxcalaImgSrc,
  },
];

const IconMap = ({ type }: { type: (typeof achievements)[number]['iconType'] }) => {
  const map: Record<
    (typeof achievements)[number]['iconType'],
    React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  > = {
    academic: GraduationCapIcon,
    event: CalendarIcon,
    trophy: TrophyIcon,
    work: BriefcaseIcon,
  };
  const Icon = map[type];

  return <Icon data-type={type} className={style.icon} />;
};

export interface AchievementsProps {}

const Achievements = ({}: AchievementsProps) => {
  return (
    <div className={style.container}>
      {achievements.map((a) => (
        <div key={a.id} className={style.achievement}>
          <div className={style.graphContainer}>
            <div className={style.graph} style={{ backgroundImage: `url(${a.image})` }} />
            <div className={style.iconContainer}>
              <IconMap type={a.iconType} />
            </div>
          </div>
          <b>{a.name}</b>
          <span>{a.date}</span>
        </div>
      ))}
    </div>
  );
};

export default Achievements;
