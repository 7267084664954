export const shuffleArray = <T>(array: T[]): T[] => {
  const tempArray = [...array];
  let currentIndex = tempArray.length;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [tempArray[currentIndex], tempArray[randomIndex]] = [tempArray[randomIndex], tempArray[currentIndex]];
  }

  return tempArray;
};

export const range = (qty: number) => {
  return new Array(qty).fill(0).map((_, i) => i);
};
