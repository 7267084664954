import { useEffect, useState } from 'react';

export const useLoopItems = <T extends any>(items: T[], secs: number): T => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((pI) => (pI + 1) % items.length);
    }, secs * 1000);

    return () => clearInterval(interval);
  }, [items.length, secs]);

  return items[currentIndex];
};
