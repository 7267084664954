import useWindowDimensions from './measurement';

const mobileWidthThreshold = 700;

export const useResponsiveness = () => {
  const { width } = useWindowDimensions();

  return {
    isMobile: width <= mobileWidthThreshold,
  };
};
