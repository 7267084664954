import React, { useState } from 'react';
import { architectureSkills } from './icons/architecture';
import { backendSkills } from './icons/backend';
import { frontEndSkills } from './icons/frontend';
import { toolsSkills } from './icons/tools';
import style from './Skills.module.css';
import { SkillModel } from './SkillsDetail/SkillsDetail';
import SkillsDetail from './SkillsDetail/SkillsDetail';
import { ReactComponent as TimesIcon } from '../../assets/icons/times.svg';

enum Skill {
  FRONT = 1,
  BACK = 2,
  ARCHITECTURE = 3,
  FRAMEWORK = 4,
}

const SKILLS_DATA: { name: string; skill: Skill; skills: SkillModel[] }[] = [
  {
    name: 'Front',
    skill: Skill.FRONT,
    skills: frontEndSkills,
  },
  {
    name: 'Back',
    skill: Skill.BACK,
    skills: backendSkills,
  },
  {
    name: 'FW & Tools',
    skill: Skill.FRAMEWORK,
    skills: toolsSkills,
  },
  {
    name: 'Cloud & Architecture',
    skill: Skill.ARCHITECTURE,
    skills: architectureSkills,
  },
];

export interface SkillsProps {}

const Skills = ({}: SkillsProps) => {
  const [openedSkill, setOpenedSkill] = useState<Skill>();

  const onClose = (e: React.MouseEvent<HTMLSpanElement>) => {
    setOpenedSkill(undefined);
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <div className={style.container}>
      {openedSkill !== undefined && (
        <div
          className={style.backdrop}
          data-variant={([] as Skill[]).includes(openedSkill as Skill)}
          onClick={() => setOpenedSkill(undefined)}
        />
      )}
      <div className={style.content}>
        {SKILLS_DATA.map((skill) => (
          <div
            key={skill.skill}
            className={style.box}
            data-active={openedSkill === skill.skill}
            onClick={() => setOpenedSkill(skill.skill)}>
            <span className={style.boxTitle}>{skill.name}</span>
            <span className={style.exitBtn} data-opened={openedSkill !== undefined} onClick={onClose}>
              <TimesIcon height="1em" />
            </span>
            <div className={style.boxContent} data-opened={openedSkill !== undefined}>
              <SkillsDetail skills={skill.skills ?? []} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;
