import React from 'react';
import Social from 'components/Social/Social';
import style from './AboutMe.module.css';
import MeImgSrc from './me.png';

export interface AboutMeProps {}

const AboutMe = ({}: AboutMeProps) => {
  return (
    <div className={style.container}>
      <span className={style.text}>
        <p>
          I've graduated from CS from one of the top universities in Colombia with a <b>full scholarship</b> and have
          participated in numerous <b className={style.competitiveProgramming}>competitive programming</b> leagues in
          Colombian national and South American-North finals.
        </p>
        <p>
          Experienced in building products ground up, like exploring different architectures and tools to fit the
          solution. Like thinking an optimal and creative solution to a problem, from the the architecture of the whole
          system to the design of the software.
        </p>
        <p>
          I have built B2B EdTech SaaS, B2B and B2C marketplaces, integrated payments infrastructure, highly available
          event-driven software, developed multiple APIs for different products and a broad experience with front-ends.
        </p>
        <br />
        <p className={style.reachOut}>
          <b>Want to reach out?</b>
          <br /> Send me an email to{' '}
          <a href="mailto:dnarvaez27@outlook.com" className={style.link}>
            dnarvaez27@outlook.com
          </a>
        </p>
      </span>
      <div className={style.graphics}>
        <img src={MeImgSrc} alt="David Narvaez" />
        <Social />
      </div>
    </div>
  );
};

export default AboutMe;
