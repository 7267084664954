import React, { useState } from 'react';
import Timeline, { TimelineNode } from 'shared/components/Timeline/Timeline';
import { range } from 'shared/utils/array';
import { getMonthsBetween } from 'shared/utils/date';
import { cls } from 'shared/utils/text';
import { ReactComponent as CakeIcon } from './icons/cake.svg';
import { ReactComponent as ClienttiImage } from './icons/clientti.svg';
import CodeImage from './icons/Code.62d324dd.gif';
import GreyLearningImageSource from './icons/grey_learning.png';
import PolyglotImageSource from './icons/polyglot.png';
import { ReactComponent as PrekiImage } from './icons/preki.svg';
import UniandesImageSource from './icons/uniandes.jpg';
import style from './Work.module.css';
import { ReactComponent as TimesIcon } from '../../assets/icons/times.svg';

interface WorkDescription {
  projectName?: string;
  bullets: string[];
  projectLink?: { link: string; name: string };
}

const experience: TimelineNode<WorkDescription[]>[][] = [
  [
    {
      id: 'preki',
      date: { from: new Date('2020-02-01'), to: new Date() },
      name: 'Preki',
      description: 'CTO, Co-Founder & Full-Stack Engineer',
      image: <PrekiImage />,
      extra: [
        {
          bullets: [
            'Improved scalability, maintainability and reliability of the system by migrating it to a serverless microservices architecture hosted in AWS.',
            'Designed and developed the product MVP with a time to market of 3 months. Used Python, Django as a monolith and PostgreSQL and Neo4j hosted in AWS EC2.',
            'Developed ~65% of frontend applications in React.js, React Native, Typescript and Capacitor.',
            'Accomplished to get into YCombinator in W21 batch being the only one technical co-founder.',
            'Led a multi regional team of engineers in LatAm whom I trained and mentored.',
          ],
          projectLink: {
            link: 'https://preki.com',
            name: 'preki.com',
          },
        },
      ],
    },
    {
      id: 'uniandes-qee',
      date: { from: new Date('2018-04-01'), to: new Date('2020-02-01') },
      name: 'Uniandes',
      description: 'Research Monitor',
      image: <img src={UniandesImageSource} alt="uniandes" className={style.workIcon} />,
      extra: [
        {
          projectName: '"Quiero Estudiar" networking investigation line',
          bullets: [
            'Designed and developed a secure anonymization system to handle sensitive information, using modern cryptography algorithms in Python.',
            'Optimized performance in DB queries by migrating a PostgreSQL into a Neo4j database',
            'Developed an ETL to load external information into the database through a Python script.',
          ],
        },
      ],
    },
    {
      id: 'uniandes-ta-sfdev',
      date: { from: new Date('2017-08-01'), to: new Date('2018-05-01') },
      name: 'Uniandes',
      description: 'Teaching Assistant',
      image: <img src={UniandesImageSource} alt="uniandes" className={style.workIcon} />,
      extra: [
        {
          projectName: 'Teams Software Development (~20 students)',
          bullets: [
            'Coached students for theorical or practical excercices and exams.',
            'Collaborated with professor to structure projects.',
            'Implemented frontend tool to help students know their progress and facilitate the grading of each one.',
          ],
        },
      ],
    },
    {
      id: 'uniandes-ra-mrq',
      date: { from: new Date('2017-05-01'), to: new Date('2017-07-01') },
      name: 'Uniandes',
      description: 'Research Assistant',
      image: <img src={UniandesImageSource} alt="uniandes" className={style.workIcon} />,
      extra: [
        {
          projectName: 'MRQ: Mapa de Riesgo Químico (Chemical Risk Map)',
          bullets: [
            'Developed the backend for the Chemical Risk Map for the AMVA (The Metropolitan Area of the Aburrá Valley) using Java with Spring Framework and JHipster to provide the basis of the REST API.',
            'Improved a PostgreSQL database queries by normalizing it and adapt it to new changes.',
          ],
        },
      ],
    },
    {
      id: 'uniandes-cupi',
      date: { from: new Date('2016-07-01'), to: new Date('2016-12-01') },
      name: 'Uniandes',
      description: 'Students Tutor',
      topOffset: 50,
      image: <img src={UniandesImageSource} alt="uniandes" className={style.workIcon} />,
      extra: [
        {
          projectName: 'CupiTaller',
          bullets: [
            'Lead students to improve their problem solving capabilities.',
            'Coached students for theorical or practical excercices and exams.',
          ],
        },
      ],
    },
    {
      id: 'uniandes-ta-apo1_1',
      date: { from: new Date('2016-01-01'), to: new Date('2016-06-01') },
      name: 'Uniandes',
      description: 'Teaching Assistant',
      lineMultiplier: 0.5,
      image: <img src={UniandesImageSource} alt="uniandes" className={style.workIcon} />,
      extra: [
        {
          projectName: 'Algorithms & OOP I (~25 students)',
          bullets: ['Coached students for theorical or practical excercices and exams.', 'Graded students projects.'],
        },
      ],
    },
  ],
  [
    {
      id: 'clientti',
      date: { from: new Date('2022-11-01'), to: new Date() },
      name: 'Clientti',
      description: 'CTO, Co-Founder & Full-Stack Engineer',
      className: style.secondColNode,
      image: <ClienttiImage />,
      extra: [
        {
          bullets: [
            'Designed and developed the production ready state of the project in 4 months using heavy event based serverless microservices using AWS services written in Python.',
            'Developed entire frontend product using React.js + Typescript with highly reusable and maintainable components.',
            'Improved observability by setting up a monitoring system using AWS OpenSearch.',
          ],
          projectLink: {
            link: 'https://clientti.com',
            name: 'clientti.com',
          },
        },
      ],
    },
    {
      id: 'polyglot',
      date: { from: new Date('2019-08-01'), to: new Date('2020-02-01') },
      name: 'Polygot',
      description: 'Full Stack Developer',
      className: style.secondColNode,
      topOffset: 550,
      image: <img src={PolyglotImageSource} alt="polyglot" className={style.workIcon} />,
      extra: [
        {
          projectName: 'YUBI - Organ transplant following system - Hospital Militar Central',
          bullets: [
            'Developed an Angular +6 Ionic mobile application to track organ transplants status along with medicines and follow up medical dates.',
          ],
        },
        {
          projectName: 'CISNE Platform - Hospital Militar Central',
          bullets: [
            'Collaborated in the development of the front end using Angular 6+ to manage pregnancy and child health control in Military hospital Kangaroo program.',
            'Developed backend endpoints in Node.js with TypeORM and PostgreSQL.',
          ],
        },
      ],
    },
    {
      id: 'uniandes-rm-cea',
      date: { from: new Date('2017-12-01'), to: new Date('2018-05-01') },
      name: 'Uniandes',
      description: 'Research Monitor - CEA',
      className: style.secondColNode,
      topOffset: 265,
      image: <img src={UniandesImageSource} alt="uniandes" className={style.workIcon} />,
      extra: [
        {
          projectName: 'CEA Uniandes (Applied Ethics Center)',
          bullets: [
            'Developed a markdown CMS to store ethical cases and be able to search them.',
            'Developed and integrated front and back end along with pandoc.',
          ],
        },
      ],
    },
    {
      id: 'uniandes-ta-apo1_2',
      date: { from: new Date('2016-08-01'), to: new Date('2016-12-01') },
      name: 'Uniandes',
      description: 'Teaching Assistant',
      className: style.secondColNode,
      topOffset: 343,
      image: <img src={UniandesImageSource} alt="uniandes" className={style.workIcon} />,
      extra: [
        {
          projectName: 'Algorithms & OOP I (~25 students)',
          bullets: ['Coached students for theorical or practical excercices and exams.', 'Graded students projects.'],
        },
      ],
    },
  ],
  [
    {
      id: 'grey-learning',
      date: { from: new Date('2019-06-01'), to: new Date('2019-12-01') },
      name: 'Grey Learning',
      description: 'Full Stack Developer',
      className: style.thirdColNode,
      topOffset: 926,
      image: <img src={GreyLearningImageSource} alt="grey learning" className={style.workIcon} />,
      extra: [
        {
          bullets: [
            'Created a recommendation system using graph algorithms.',
            'Developed frontend in Angular 6+ for the landing page and the web application of the business.',
            'Developed a REST API in Python using Django framework to expose business services.',
          ],
        },
      ],
    },
  ],
];

export interface WorkProps {}

const getLineMultiplier = (d1: Date, d2: Date) => {
  const months = getMonthsBetween(d1, d2);
  return months / 12;
};

const Work = ({}: WorkProps) => {
  const [selectedWork, setSelectedWork] = useState<TimelineNode<React.ReactNode>>();

  const getNode = ({
    date,
    name,
    description,
    extra,
    ...rest
  }: TimelineNode<WorkDescription[]>): TimelineNode<React.ReactNode> => {
    const lineMultiplier = getLineMultiplier(date.from, date.to);

    const res: TimelineNode<React.ReactNode> = {
      date,
      name,
      description,
      lineMultiplier,
      ...rest,
    };

    if (lineMultiplier > 1) {
      res.pointsOfInterest = range(Math.floor(lineMultiplier)).map((_, i) => ({
        bottom: 200 + 200 * i,
        className: style.poi,
        content: (
          <>
            <span className={style.cakeIconContainer}>
              <CakeIcon className={style.cakeIcon} />
            </span>
            <small className={style.poiText}>
              {i + 1} year{i === 0 ? '' : 's'}
            </small>
          </>
        ),
      }));
    }

    if (selectedWork?.id === rest.id) {
      res.className = cls(res.className, style.selectedWork);
    }

    res.extra = (
      <div>
        {extra?.map((project, index) => (
          <React.Fragment key={index}>
            {!!project.projectName && <b>{project.projectName}</b>}
            <ul>
              {project.bullets.map((bullet, index) => (
                <li key={index}>{bullet}</li>
              ))}
            </ul>
            {project.projectLink && (
              <>
                <br />
                <br />
                <span>
                  See more of the project here:{' '}
                  <a href={project.projectLink.link} className={style.link} target="_blank" rel="noreferrer">
                    {project.projectLink.name}
                  </a>
                </span>
              </>
            )}
          </React.Fragment>
        ))}
      </div>
    );

    return res;
  };

  const onWorkClick = (node: TimelineNode<React.ReactNode>) => {
    setSelectedWork(node);
  };

  return (
    <div className={style.container}>
      <div className={style.infoContainer} data-empty={!selectedWork?.extra}>
        <div className={style.exitInfo} onClick={() => setSelectedWork(undefined)}>
          <TimesIcon height="1em" />
        </div>
        <span className={style.info} data-empty={!selectedWork?.extra}>
          {selectedWork?.extra ?? (
            <div className={style.noWorkSelected}>
              <img src={CodeImage} alt="working" />
              <span>Scroll to see more</span>
              <span>Select a work logo to see details</span>
            </div>
          )}
        </span>
      </div>
      <div className={style.timeline}>
        {experience.map((nodes, index) => (
          <Timeline
            key={index}
            nodes={nodes.map(getNode)}
            onClick={onWorkClick}
            className={cls(index !== 0 && style.secondCol)}
          />
        ))}
      </div>
    </div>
  );
};

export default Work;
