import App from 'App';
import AboutMe from 'components/Bio/AboutMe/AboutMe';
import Achievements from 'components/Bio/Achievements/Achievements';
import Bio from 'components/Bio/Bio';
import Extra from 'components/Bio/Extra/Extra';
import Home from 'components/Home/Home';
import Skills from 'components/Skills/Skills';
import Work from 'components/Work/Work';
import { createHashRouter, RouterProvider } from 'react-router-dom';

const router = createHashRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/bio',
        element: <Bio />,
        children: [
          {
            path: '/bio/about-me',
            element: <AboutMe />,
          },
          {
            path: '/bio/achievements',
            element: <Achievements />,
          },
          {
            path: '/bio/extra',
            element: <Extra />,
          },
        ],
      },
      {
        path: '/work',
        element: <Work />,
      },
      {
        path: '/skills',
        element: <Skills />,
      },
    ],
  },
]);

export const Navigation = () => <RouterProvider router={router} />;
