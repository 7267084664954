import { SkillModel } from 'components/Skills/SkillsDetail/SkillsDetail';
import { ReactComponent as awsapigatewayIcon } from './aws-apigateway.svg';
import { ReactComponent as awscloudfrontIcon } from './aws-cloudfront.svg';
import { ReactComponent as awscloudsearchIcon } from './aws-cloudsearch.svg';
import { ReactComponent as awscognitoIcon } from './aws-cognito.svg';
import { ReactComponent as awsdocdbIcon } from './aws-docdb.svg';
import { ReactComponent as awsdynamodbIcon } from './aws-dynamodb.svg';
import { ReactComponent as awsec2Icon } from './aws-ec2.svg';
import { ReactComponent as awseventbridgeIcon } from './aws-eventbridge.svg';
import { ReactComponent as awslambdaIcon } from './aws-lambda.svg';
import { ReactComponent as awsLocationIcon } from './aws-location.svg';
import { ReactComponent as awsopensearchIcon } from './aws-opensearch.svg';
import { ReactComponent as awsroute53Icon } from './aws-route53.svg';
import { ReactComponent as awss3Icon } from './aws-s3.svg';
import { ReactComponent as awssesIcon } from './aws-ses.svg';
import { ReactComponent as awssnsIcon } from './aws-sns.svg';
import { ReactComponent as awssqsIcon } from './aws-sqs.svg';
import { ReactComponent as awstimestreamIcon } from './aws-timestream.svg';
import { ReactComponent as awsvpcIcon } from './aws-vpc.svg';
import { ReactComponent as awsIcon } from './aws.svg';
import { ReactComponent as firebaseIcon } from './firebase.svg';

export const architectureSkills: SkillModel[] = [
  { name: 'AWS', expertise: 'advanced', icon: awsIcon },
  { name: 'Lambda', expertise: 'expert', icon: awslambdaIcon },
  { name: 'DynamoDB', expertise: 'expert', icon: awsdynamodbIcon },
  { name: 'DocumentDB', expertise: 'expert', icon: awsdocdbIcon },
  { name: 'TimeStream', expertise: 'advanced', icon: awstimestreamIcon },
  { name: 'EventBridge', expertise: 'expert', icon: awseventbridgeIcon },
  { name: 'SQS', expertise: 'expert', icon: awssqsIcon },
  { name: 'SNS', expertise: 'expert', icon: awssnsIcon },
  { name: 'S3', expertise: 'expert', icon: awss3Icon },
  { name: 'API Gateway', expertise: 'advanced', icon: awsapigatewayIcon },
  { name: 'CloudFront', expertise: 'advanced', icon: awscloudfrontIcon },
  { name: 'CloudSearch', expertise: 'experienced', icon: awscloudsearchIcon },
  { name: 'Cognito', expertise: 'advanced', icon: awscognitoIcon },
  { name: 'OpenSearch', expertise: 'experienced', icon: awsopensearchIcon },
  { name: 'Route53', expertise: 'experienced', icon: awsroute53Icon },
  { name: 'SES', expertise: 'experienced', icon: awssesIcon },
  { name: 'EC2', expertise: 'experienced', icon: awsec2Icon },
  { name: 'VPC', expertise: 'beginner', icon: awsvpcIcon },
  { name: 'AWS Location', expertise: 'experienced', icon: awsLocationIcon },
  { name: 'Firebase', expertise: 'experienced', icon: firebaseIcon },
];
