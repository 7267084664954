import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import style from './Bio.module.css';

export interface BioProps {}

enum BioPath {
  ABOUT_ME = 'about-me',
  ACHIEVEMENTS = 'achievements',
  EXTRA = 'extra',
}

const Bio = ({}: BioProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const currentPath = useMemo(() => {
    return pathname.split('/')[2];
  }, [pathname]);

  useEffect(() => {
    if (currentPath === undefined) {
      navigate(BioPath.ABOUT_ME);
    }
  }, [currentPath, navigate]);

  return (
    <div className={style.container}>
      <div className={style.menu}>
        <button data-active={currentPath === BioPath.ABOUT_ME} onClick={() => navigate(BioPath.ABOUT_ME)}>
          About me
        </button>
        <button data-active={currentPath === BioPath.ACHIEVEMENTS} onClick={() => navigate(BioPath.ACHIEVEMENTS)}>
          Achievements & Events
        </button>
        <button data-active={currentPath === BioPath.EXTRA} onClick={() => navigate(BioPath.EXTRA)}>
          Extra
        </button>
      </div>
      <div className={style.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default Bio;
