import React from 'react';
import { getMonthsBetween } from 'shared/utils/date';
import { cls } from 'shared/utils/text';
import style from './Timeline.module.css';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export interface TimelineNode <T extends any> {
  id: string;
  date: { from: Date; to: Date };
  name: string;
  description: string;
  className?: string;
  topOffset?: number;
  lineMultiplier?: number;
  image: React.ReactNode;
  pointsOfInterest?: {
    bottom: number;
    content: React.ReactNode;
    className?: string;
  }[];
  extra?: T;
}

const parseDateRange = <T extends any>(date: TimelineNode<T>['date']): string => {
  const months = getMonthsBetween(date.from, date.to);

  let text: string[] = [];
  if (months > 12) {
    text.push(`${Math.floor(months / 12)} years`);
    text.push(`${(months % 12).toFixed(0)} months`);
  } else {
    text.push(`${months} months`);
  }

  return text.join(', ');
};

export interface TimelineProps<T extends any> {
  nodes: TimelineNode<T>[];
  className?: string;
  onClick: (node: TimelineNode<T>) => void;
}

const Timeline = <T extends any>({ nodes, onClick, className }: TimelineProps<T>) => {
  return (
    <div className={cls(style.container, className)}>
      <div className={style.content}>
        {nodes.map((node) => (
          <div key={`${node.id}`} className={cls(style.node, node.className)} style={{ marginTop: node.topOffset }}>
            <span className={style.legend}>
              {node.date.from.getUTCFullYear()} {months[node.date.from.getUTCMonth()]}
            </span>
            <div className={style.legendLine} />
            <div className={style.nodeContent}>
              <div className={style.icon} onClick={() => onClick(node)}>
                {node.image}
              </div>
              <div className={style.title}>
                <span className={style.name}>{node.name}</span>
                <b>{node.description}</b>
                <span>{parseDateRange(node.date)}</span>
              </div>
              <div className={style.line} style={{ height: 200 * (node.lineMultiplier ?? 1) }}>
                {node.pointsOfInterest?.map((poi, i) => (
                  <div key={i} className={cls(style.poi, poi.className)} style={{ bottom: poi.bottom }}>
                    {poi.content}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
